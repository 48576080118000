<template>
  <!-- Error page-->
  <div class="misc-wrapper">

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">

        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          alt="close"
        />

     
      </div>

    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  data() {
    return {
      imgUrl: require('@/assets/images/pages/close.svg'),
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
